import React, { useEffect } from "react";
import {
    BrowserRouter, Route, Routes, Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Space, Spin } from "antd";
import { Home } from "../views/Home";
import { LoginUI } from "../views/Login";
import { Logout } from "../views/Logout";

import { PageNotFound } from "../views/NotFound/PageNotFound";
import { PrivateRoute } from "./protected";
import "./router.css";
import { Media } from "../views/Media/Media";
import { Header } from "../components/oneTimeComponents/header/Header";
import { Footer } from "../components/oneTimeComponents/footer/Footer";
import { useDarkMode } from "./Darkmode";
import { Account } from "../views/Account/Account";
import { isUserLoggedIn, validateTokenAndFetchUserInfo } from "../reducers/user";
import { useMessage } from "../hooks/AntComponents/useMessage";
import { Youtube } from "../views/Youtube/Youtube";
import { HaalWeg } from "../views/haal_weg";

export function AppRoutes() {
    const dispatch = useDispatch();
    const {
        status, token, authChecked, error,
    } = useSelector((state) => state.user);
    const isLoggedIn = useSelector(isUserLoggedIn);

    useEffect(() => {
        if (token && !authChecked && !isLoggedIn) {
            dispatch(validateTokenAndFetchUserInfo());
        } else if (status !== "loggedOut") {
            dispatch({ type: "user/authCheckComplete" });
        }
    }, [dispatch, token, authChecked]);

    const message = useMessage();

    useEffect(() => {
        if (status === "succeeded") {
            message.success({ content: "Succesvol ingelogd" });
        } else if (status.includes("Token expired")) {
            message.error({ content: "Token is verlopen, log opnieuw in." });
        } else if (status === "Not authorized to access this resource") {
            message.error({ content: "Je bent niet bevoegd om deze bron te benaderen." });
        } else {
            console.log("Token heeft geen; is verlopen message status maar is ook nog niet succesvol.");
            // message.error({ content: "Het lijkt er op dat je token is verlopen." });
        }
    }, [dispatch, status, message]);

    const isLoading = status === "loading" || status === "tryingToken";

    return (
        // min-h-screen
        <div className="" id="router">
            <Routes>
                <Route path="/" exact strict element={<Home />} />
                <Route path="/box/" element={<Home />} />

                <Route path="/login" element={<LoginUI />} />
                <Route
                    path="/logout"
                    element={(
                        <PrivateRoute authenticated={isLoggedIn} loading={isLoading}>
                            <Logout />
                        </PrivateRoute>
                    )}
                />

                <Route // overzicht van de lijsten en bijv add Media of lijst knop.
                    path="/media/*"
                    element={(
                        <PrivateRoute authenticated={isLoggedIn} loading={isLoading}>
                            <Media />
                        </PrivateRoute>
                    )}
                />

                <Route
                    path="/account/*"
                    element={(
                        <PrivateRoute authenticated={isLoggedIn} loading={isLoading}>
                            <Account />
                        </PrivateRoute>
                    )}
                />
                <Route
                    path="/youtube/*"
                    element={(
                        <PrivateRoute authenticated={isLoggedIn} loading={isLoading}>
                            <Youtube />
                        </PrivateRoute>
                    )}
                />

                <Route path="/haal_weg" element={<HaalWeg />} />

                <Route path="/nietgevonden" exact strict element={<PageNotFound />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </div>
    );
}
